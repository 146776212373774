<template>
  <div class="list-content-page">
    <div class="ym-title">出栏信息</div>
    <!-- 条件筛选 -->
    <div class="ym-panel-general">
      <el-form
        class="fl"
        :inline="true"
        :model="form"
      >
        <el-form-item prop="outNum" label="出栏编号：">
          <el-input
            v-model="form.outNum"
            size="medium"
            placeholder="全部"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          class="ym-input-left"
          prop="batchNum"
          label="产品批次编号："
        >
          <el-input
            v-model="form.batchNum"
            size="medium"
            placeholder="全部"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item class="ym-input-left" prop="outCompanyId" label="出栏下游商户：">
          <select-page
            label="companyName"
            value="companyId"
            apiFile="currency"
            apiName="listCompanys"
            placeholder="请选择出栏下游商户"
            filter="companyName"
            :selected.sync="companyId"
            :params="companyParams"
            @change="onCompanyChange"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item>
          
         <el-button class="ym-input-left" type="primary" round size="medium" @click="submit">查询</el-button>
         <el-button plain round size="medium" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="ym-panel-table">
      <!-- 操作按钮 -->
      <div class="btns">
        <el-button
          type="primary"
          round
          icon="el-icon-plus"
          size="medium"
          @click="toAdd"
          >新增
        </el-button>
      </div>
      <!--表格-->
      <el-table
        :data="tableData"
        v-loading="loading"
        highlight-current-row
        class="ym-table"
        size="mini"
        stripe
        border
        :row-style="{ height: '40px' }"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="outNum"
          label="出栏编号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          min-width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="batchNum"
          label="产品批次编号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="outCompanyName"
          label="出栏下游商户"
          min-width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="outQty"
          label="出栏数量"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="出栏时间"
          min-width="180"
          align="center"
        >
          <template slot-scope="scope">
            <i v-if="scope.row.createTime" class="el-icon-time"></i>
            <span style="margin-left: 6px">{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="afcBatchOutId"
          align="center"
          label="操作"
          width="150"
          fixed="right"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.chainFlag===1">
                <el-button type="text" @click="toSave(scope.row)">编辑</el-button>
                <el-button type="text" @click="deleteTable(scope.row)">删除</el-button>
            </template>
            <el-button type="text" @click="toGet(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      companyId: '',
      companyParams: {
        enableFlag: 1,
        companyTypeIds: [100, 102] // 销售商
      },
      tableData: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 40
      },
      form: {
        productName: '',
        batchNum: '',
        outCompanyId: '',
        outCompanyName: ''
      }
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  methods: {
    toAdd() {
      this.$router
        .push({ path: '/listFowlOuts/saveFowlOut' })
        .catch((err) => err)
    },
    indexMethod(index) {
      if (index + 1 < 10) {
        return '0' + (index + 1)
      }
      return index + 1
    },
    toGet(row) {
      this.$router.push({ path: '/listFowlOuts/getFowlOut', query: { id: row.afcBatchOutId }})
    },
    toSave(row) {
      this.$router.push({ path: '/listFowlOuts/saveFowlOut', query: { id: row.afcBatchOutId }})
    },
    // 选择下游商户
    onCompanyChange(val) {
      if (!val) {
        this.form.outCompanyId = ''
        this.form.outCompanyName = ''
      } else {
        this.form.outCompanyId = val.companyId
        this.form.outCompanyName = val.companyName
      }
    },
    pageSizeChange(val) {
      this.page.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.page.currentPage = val
      this.getList()
    },
    // 条件搜索
    submit() {
      this.getList()
    },
    // 条件重置
    reset() {
      this.form.batchNum = ''
      this.form.outNum = ''
      this.form.productName = ''
      this.form.outCompanyId = ''
      this.form.outCompanyName = ''
      this.companyId = ''
      this.getList()
    },
    // 删除列表
    deleteTable(row) {
      // 确认删除操作
      this.$confirm('确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除操作
        this.remove(row.afcBatchOutId)
      }).catch(() => {
      })
    },
    async getList() {
      const params = {
        batchNum: this.form.batchNum,
        outNum: this.form.outNum,
        productName: this.form.productName,
        outCompanyId: this.form.outCompanyId,
        size: this.page.pageSize,
        current: this.page.currentPage
      }
      for (const key in params) {
        if (params[key] === '' || params[key] === 'undefined') {
          delete params[key]
        }
      }
      this.loading = true
      const { data } = await this.$http.fowlOutsInfor.listFowlOuts(params)
      if (data.code === '0') {
        this.tableData = data.data.records
        console.log('列表：', this.tableData)
        this.page.total = parseInt(data.data.total)
      } else {
        this.$message.error(data.msg || '获取列表失败')
      }
      this.loading = false
    },
    // 删除
    async remove(afcBatchOutId) {
      const params = {
        afcBatchOutId: afcBatchOutId
      }
      this.loading = true
      const { data } = await this.$http.fowlOutsInfor.removeFowlOut(params)
      if (data.code !== '0') return this.$message.error(data.msg || '删除失败')
      else {
        this.$message.success(data.msg || '删除成功')
        if (this.tableData.length === 1) {
          this.page.currentPage -= this.page.currentPage
          this.getList()
        } else {
          this.getList()
        }
        this.loading = false
      }
    }

  }
}
</script>

<style lang="scss" scrope>
.list-content-page {
  .el-form-item {
    margin-bottom: 0;
  }
  .ym-panel-table {
    .icon-btn {
      font-size: 12px;
      color: #3e6df5;
      margin-right: 4px;
    }
  }
  .btns {
    text-align: right;
  }
  .ym-table {

    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
  }

  //按钮图标样式调整
  .iconfont {
    font-family: 'iconfont' !important;
    font-size: 14px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  //下载文件样式
  .download-title{
    color:#3e6df5;
  }
  .download-title:hover{
    color:#668bfa;
    text-decoration: underline;
  }
}
</style>
